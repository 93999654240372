
.main{
    max-width: 1100px;
    margin: auto;
    padding: 100px 0px;
}
.main-success {
    max-width: 1100px;
    margin: auto;
    padding: 100px 0px;
    padding-bottom: 0px;
}
.accent-bg {
    background-color: #E7E7FE;
    margin: auto 0px;
    width: auto;
}
.content-height {
    margin-top: 18%;
}
.abs-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.description {
    font-weight: lighter;
    font-family: 'Helvetica-light';
    font-size: 80px;
    padding-right: 10px;
}
.align {
    font-weight: bold;
    font-size: 80px;
    color: #212121;
}
.home-align {
    height: calc(100vh - 200px);
}
.small {
   
    font-size: 34px;
    display: flex;
    justify-content: flex-end;
    font-weight: lighter;
}
.nav {
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
}
.header {
    font-size: 34px;
    line-height: 39px;
    font-weight: 600;
    color: #212121;
    text-align: left;
    letter-spacing: 0.87px;
}

.flex-center {
    text-align: center;
    display: flex !important;
    flex-direction: row;
}
.info {
    color: #6c6b71;
    padding: 10px 0px;
    text-align: left;   
}
.step {
    max-width: 80%;
    margin: auto;
}
.card-align-right {
    margin: 100px;
}
.even-process {
    margin-left: 170px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-align {
    margin: 30px auto;
}
.card-row:nth-child(odd) .step{
    margin-left: auto;
    flex-direction: row-reverse;
}
.card-row:nth-child(4n+1) .card-align{
    margin-left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;     
}
.card-row:nth-child(4n - 1) .card-align{
    margin-right: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;     
}

.number {
    top: 30px;
}
.card-row {
    position: relative;
}
.card-align-right {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.illustrations {
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.steps {
    flex: 2;
}
.numbers {
    width: 24px;
    opacity: 0.9;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: center; */
    background-position: center top !important;
}
.page-title{
    letter-spacing: 2.4px;
    font-weight: bold;
    text-align: center;
    font-size: 44px;
}
.primary-button{
    width:250px;
    height:44px;
    font-family: 'fontSemiBold'; 
    background-color: #c8102e;
    text-transform: capitalize;
    box-shadow: unset;
}
.Location-nav {
    text-align: center;
}
.Location-nav span {
    font-size: 24px;
margin: 10px;
color: #724040;
font-weight: bold;    font-style: italic;

}
.benefit {
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    min-width: 366px;
    margin: 30px 0px;
}
.maps {
    width: 50%;
    min-height: 100%;
    position: relative; 
}
.main-blue-maps {
    position : absolute;
    font-size: 44px;
    top: 5%;
    left: 50%;
    font-weight: bold;
    background-color: #fafafad4;
}
.right {
    background-color:#E7E7FE;
    margin: unset;
    padding: 50px;
}
.grid-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #ffff;

}
.maps-iframe {
    min-height: unset;
}
.icon {
    opacity: 0.8;
    width: 64px;
    height: 64px;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 38.4px;
    max-width: 350px;
    padding-top: 8px;
}
.infos {
    padding-top: 3px;
    font-size: 12px;
    line-height: 18px;
    max-width: 250px;
    text-align: center;
}
.content-height p {
    text-shadow: -0.58px 0.8px 1px #102555;
}
.events {
    background-color: #fafafa;
}
.events-maxwidth {
    max-width: 500px;
    padding: 0px;
}
.quote-icon-left {
    width: 64px;
    height: 64px;
    transform: rotate(183deg);
    opacity: 60%;
    padding-top: 10px;
}
.quote-icon-right {
    width: 64px;
    height: 64px;
    margin: 10px 675px;
    opacity: 60%;
}


.contact_container * {
    color: #012169 !important;
    
}

.contact_container{
    background: #fafafa;
    box-shadow:none;
    margin:0px;
    padding:0px;
    color: #012169;
}
.contact_container .MuiInput-underline:before {
    border-color:unset !important;
}
.contact_container button {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px !important;
}
.contact_form_inline {
    /* max-width: 85%;
    margin: 30px auto; */
}
.grid-logos {
    display: flex;
    flex-wrap: wrap;  
}
.align-university {
    margin: 10px;
}
.university-size {
    width: 200px;
    height: 200px;
    object-fit: contain;
    object-position: center;
    vertical-align: middle;
}
.universities-align {
    background-position: center;
    background-size: contain;
}


.blue-light-bg {
    background-color: #E7E7FE;
}

.blue-gradient {
  background: #1e3c72; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1e3c72, #2a5298); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1e3c72, #2a5298);
}
.background-image {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: -1;
    background-size: cover;
}
.social-links {
    margin-top: 50px;
}

.carousel-container{
    width: 800px; 
    margin: 50px auto;
}

.logo {
    width: 20%;
    height: 74px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
}

.nav-link {
    color: #373b6b;
    font-weight: 500;
    padding: 0px 30px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'fontMedium' !important;
    letter-spacing: 0.87px;
}

.footer-navs {
    min-width: 400px;
}

#gd-nav header {
    background: #f7f7fbc7 !important;
    border-bottom: 1px solid #284c8e;
}

.white-text#gd-nav header {
    background: transparent !important;
    border: none !important;
}

.white-text .nav-link {
    color: rgb(12, 12, 12) !important;
    transition: 0.4s;
}

.MuiToolbar-gutters{
    display: flex;
    justify-content: space-between;
}

.header-info{
    margin: 30px 0px; 
    max-width: 450px;
}

.sub-page-header {
    font-size: 34px;
    line-height:42px;
    letter-spacing:1px;
    font-weight:200;
}

.sub-page-header-container{
    max-width: 800px
}

.carousel__dot-group{
    display: none;
}

.link{
    text-decoration: none;
}

.quick-link{
    font-weight: 400;
    color: #E7E7FE;
    font-size: 18px; 
    line-height: 22px;
    letter-spacing: 0.87; 
    text-transform: capitalize;
    padding-left: -13px; 
    padding: 10px 0px;
}



@media only screen and (max-width: 471px) {
    html,body{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .flex-fluid {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
    }
    .flex-fluid.reverse {
        flex-direction: column-reverse;
    }
}


@media all and (min-width:320px) and (max-width: 480px){
    .logo{
        width: 50%;
    }
    .footer-navs{
        min-width: 100%;
    }
    .benefit {
        display: flex;
        justify-content: center; 
        align-items: center; 
        flex-direction: column;
        min-width: 100%;
    }
    .main{
        max-width: 90%;
        margin: auto;
        padding: 100px 0px 60px 0px;
    }
    .background-image {
        height: auto;
    position: relative;
    bottom: 0;
    width: 100vw;
    left: 13px;
    }
    .gloablMapview-container {
        width: 100%;
    }
    
    .Location-nav {
        margin-left: -10px;
        margin-top: -40px;
    }
    .Location-nav span {
        font-size: 18px;
        margin: 10px;
        color: #cfaaaa;
        font-weight: bold;    font-style: italic;
        
    }
    .title {
        text-align: center;
    }
    .maps {
        width: 100%;
        min-height: 60vh;
        position: relative; 
    }
    .main-blue-maps{
        font-size: 30px;
    }
    
    .maps-iframe{
        min-height: 60vh;
    }
    
    .icons-center {
        text-align: center;
    }
    .quick-links {
        padding: 10px 0px;
        margin-top: 30px;
    }
    .social-links {
        margin-top: 20px;
    }
    .carousel-container{
        margin: 0px auto;
        width: 100%;
    }
    .main-success{
        max-width: 90%;
        padding: 60px 0px 0px 0px;
    }
    .slide___3-Nqo{
        height: 100vh;
    }
    .quote-icon-right{
        margin: 0;
        margin-left: auto;
    }
    .right {
        background-color:#E7E7FE;
        padding: 20px;
    }
    .content-height {
        margin-top: 90px;
        padding: 25px 20px 0px 20px;
    }

    .header-info{
        max-width : 100%;
        margin: 10px 0px 20px 0px;
    }
    .info{
        font-weight: 400;
        margin: 10px 0px;
        max-width: 100%;
    }

    .sub-page-header{
        font-size: 20px !important;
        line-height: 24px !important;
        letter-spacing: 0.8px !important;
    }
    .sub-page-header-container{
        max-width: 100%;
    }
    .step {
        max-width: 100%;
    }
    .header{
        font-size: 24px;
        line-height: 26px;
        font-weight: bold;
    }
    .numbers{
        width: 18px;
        height: 18px;
    }
    .title{
        font-size: 22px;
        line-height: 26.4px;
    }
    .home-align {
        height: 100%;
    }
    .simple-nav{
        color: #c8102e ;
        font-weight: 400;
        padding: 0px 20px;
        font-size: 17px;
        text-transform: capitalize;
    }
    .illustrations {
        height: 80vw!important;
    }
    .step {
        max-width: unset;
    }
}

/* ipad  */
@media all and (min-width:768px) and (max-width:1024px ){
    .main{
        max-width: 80%;
        margin: auto;
        padding: 90px 0px;
    }
    .benefit {
        display: flex;
        justify-self: unset;
        min-width: unset;
        align-items: center; 
        flex-direction: column;
        margin: 30px 0px;

    }
    .grid-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #ffff;
    }
    .left {
        margin-left: unset;
    }
    .flex {
        margin: unset;
    }
    .events-maxwidth {
        padding: 10px 0px 10px 0px;
    }
    .maps {
        width: 100%;
        min-height: unset;
        position: relative; 
    }
    .main-blue-maps{
        font-size: 20px;
    }
    
    .maps-iframe{
        min-height: 50vh;
    }
    .contact_form { 
        background-color: #fafafa;
        padding: 50px 0px;
    }
    .form-container {
        margin: 0 auto;
        max-width: 450px;
        min-width: unset;
    }
    .carousel-container{
        width: 740px;
    }
    .mobile-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
    .icons-center {
        text-align: center;
    }
    .step {
        max-width: unset;
    }
    
    
    
}


@media all and (min-width:1024px) and (max-width: 1366px){
    .main{
        max-width: 80%;
        margin: auto;
        padding: 100px 0px;
    }
    .benefit {
        display: flex;
        justify-self: center;
        align-items: center; 
        flex-direction: column;
        min-width: 366px;
        margin: 30px 0px;
    }
    .grid-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #ffff;
    }
}