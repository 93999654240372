
// should be according to the first number

$grey-01: rgba(245, 245, 245, 0.05);  
$grey-10: #f9f9f9;
$grey-13: #f3f3f3;
$grey-15: #fcfcfc;
$grey-20: #efefef;
$grey-30: #e8e5e5;
$grey-50: #dcdcdc;
$grey-55: #b9b9b9;
$grey-60: #6b6a6a;
$grey-70: #797777;
$grey-80: #8c8c8c;
$grey-100: #5d5b5b;
$grey-110: #545454;
$grey-120: #3a3939;
$grey-140: #4e4e4e;
$glassy-black-bg: rgba(74, 74, 74, 0.74);
$black: #000;
$black-05: rgba(0, 0, 0, 0.5);
$black-15: rgba(0, 0, 0, 0.15);

$white-05: rgba(255, 255, 255, 0.5);
$white-08: rgba(255, 255, 255, 0.8);
$white: #fff;

$green-30: #2fd22f;
$green-80: #2d920e;
$green: #5dbb00;
$green-60: #63cca2;
$green-50: #406e1a;
$green-02: rgba(195, 250, 200, 0.3);

$yellow-lite: rgba(255, 233, 115, 0.20);
$yellow-50: #f5d444;
$yellow-100: #8f6003;

$sky-blue-02: rgba(222, 247, 255, 0.2);
$sky-blue-05: rgba(222, 247, 255, 0.5);
$sky-blue-90: #94daeb;
$light-blue: #64dad5;
$sky-blue: #5ed3f9;
$blue-30: #81ceec;
$blue-80: #045577;
$blue-grey-07: rgba(104, 140, 151, 0.7);
$greyish-blue: #64c7c3;
$huex-blue-dark: #61d0cb;
$dark-blue: #3f51b5;
$dark-brilliant-blue: #0f8298;

$red: #f00;
$huex-red: #ff7c7c;
$huex-red-dark: #ff5254;
$red-30: #ff7d7d;
$red-80: #af1111;
$huex-red-light: rgba(255, 124, 124, 0.8);
$huex-red-50: rgba(255, 124, 124, 0.5);
$fb-color: #3B5998;
$linkedIn-color: #4875B4;