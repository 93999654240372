body {
  margin: 0;

  color: black;
}
//   body * {
//     font-family: "Times New Roman" ;
//   }

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

.green-button {
  background-color: #33ba74;
  color: white;
}

.pointer {
  cursor: pointer;
}

.success-checkmark {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4CAF50;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: '';
  height: 100px;
  position: absolute;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
}

.row-scrollable {
  //white-space: nowrap;
  overflow-x: auto;
  width: 100%;
  scrollbar-color: transparent;
  scrollbar-width: 0;
}

.row-scrollable::-webkit-scrollbar {
  display: none;
}

.row-scrollable > * {
  display: inline-block;
  width: auto;
}

.grid {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}
.grid-content {
  margin-left: 40px;
}

.padding-top-video {
  padding-top: 200px;
}

.modal__escape {
  right: 10px;
  top: 45px;
  position: absolute;
  padding: 25px;
}

.outlined-textarea {
  // background: none;
}

.nav-text {
  padding-right: 30px;
  cursor: pointer;
  transition: 0.4s;
  opacity: 0.85;
  line-height: 40px;
  &:hover {
    letter-spacing: 2px;
    transition: 0.4s;
    opacity: 1;
    font-weight: 600;
    background-image: linear-gradient(
      43deg,
      #4158d0 0%,
      #c850c0 46%,
      #ffcc70 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.nav-link {
  color: #000;
  padding: 0 20px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  display: block;
  font-size: 20px;
}

.nav-link-container {
  margin: 0 20px;
}

.no-margin {
  margin: 0 !important;
}

.nav-title {
  color: #f1f1f1;
  font-size: 30px;
  font-weight: bold;
  margin-top: inherit;
}

.padding-l-r-15 {
  padding: 0px 15px !important;
}
.padding-top-grid {
  margin-top: 200px;
}

.scroll-down-position {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  align-self: center;
  margin-left: 25%;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

.modal-close {
  position: absolute;
  right: 0;
  top: 10;
  z-index: 2;
  padding-right: 15px;
}

.simple-nav {
  padding-right: 30px;
  color: black;
  font-weight: bold;
  align-content: center;
  cursor: pointer;
  &:hover {
    color: #33ba74;
  }
}

.nav-mobile-menu-paper {
  background-color: rgba(255, 255, 255, 0.9) !important;
  width: 100%;
  height: 100%;
}

.nav-mobile-close {
  padding: 14px;
  text-align: right;
}

.nav-title {
  color: grey;
  font-size: 30px;
  font-weight: bold;
  margin-top: revert;
  // margin-bottom:0;
}

.nav-bar {
  flex: 1;
  margin-left: 30px;
  width: 200px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0px;
}

.text-black {
  color: #000;
}

.text-white {
  color: white !important;
}

.grey-text {
  color: #656464;
}


.btn-row > *:nth-child(even) {
}

.btn-row > *:first-child {
  margin-left: 0px;
}

.btn-row > * {
  margin: 0px 5px !important;
}


.btn-row {
  display: flex;
}

.grey-card-box {
  font-size: 16px;
  padding: 20px 25px;
  background-color: #f5f5f5 solid;
  border-radius: 4px;
  border: 1px solid #d0cfcf;
  word-break: break-all;
}

.sub-content {
  text-align: center;
  padding: 10px;
}

.secondary-heading {
  font-size: 30px;
  color: #000;
}
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.green-bg {
  background-color: #33ba74 !important;
}

.full-screen {
  width: 100%;
  height: 100vh;
}
.clickable {
  font-size: 20px;
  cursor: pointer;
}

.main-heading {
  color: black;
  font-weight: bold;
  font-size: 70px;
  line-height: 85px;
}

.text {
  font-size: 23px;
}

.center {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.flex-middle {
  display: flex;
  align-items: center;
}

.margin-t-b-5 {
  margin: 5px 0px !important;
}

.warning-box {
  padding: 10px;
  background-color: rgba(255, 255, 102, 0.25);
  border-radius: 4px;
  border: 1px solid #e6d934;
  color: #a55400;
}

.warning-box svg {
  color: orangered;
}

.warning-box ._line {
  background-color: #e6d934;
}

.warning-box button {
  box-shadow: 0px 0px 0px 0px !important;
  background-color: rgba(247, 149, 3, 0.85);
}

.warning-box button:hover {
  background-color: rgba(247, 149, 3, 1);
}

.color-warning {
  color: #ec940e !important;
}

.bg-particles {
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
  #tsparticles {
    height: 100%;
  }
}

.big-logo {
  //background-image: url('../assets/logo\ big.png');
  width: 200px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0px;
}

.big-title {
  font-size: 24px;
  line-height: 30px;
}

.client-logo {
  width: 200px;
  object-fit: contain;
  margin: auto;
}

.red-carpet {
  width: 100%;
  height: 250px;
  border-top: 20px solid red;
  border-bottom: 20px solid red;
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.section-container {
  padding: 70px 50px 0px;
}

.margin-auto {
  margin: auto !important;
}

.margin-t-b-20 {
  margin: 20px 0px;
}

.margin-10 {
  margin: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-it {
  font-size: 225px;
  line-height: 180px;
  font-weight: bold;
  margin: 0;
}

.client-panel {
  margin-top: 80px !important;
}

.main-text {
  font-size: 90px;
  margin: 0;
}

.margin-t-40 {
  margin-top: 40px !important;
}

.hover-gradient-text .title {
  transition: 0.4s;
}

.full-width {
  width: 100%;
  max-width: 100% !important;
}

.rotate-45 {
  transform: rotate(-45deg);
}

.font-22 {
  font-size: 22px;
}

.font-18 {
  font-size: 18px;
}

.flex-center {
  display: flex;
  align-items: center;
  margin: 0;
}
.margin-l-r-10 {
  margin: 0px 10px;
}

.margin-l-10 {
  margin-left: 10px;
}

.margin-l-30 {
  margin-left: 30px;
}

.margin-l-40 {
  margin-left: 40px;
}

.margin-b-40 {
  margin-bottom: 40px;
}

.margin-t-40 {
  margin-top: 40px;
}

.big-icon {
  font-size: 28px !important;
}

.video {
  width: 100%;
  // border: 1px solid black;
}

.video-size {
  width: 100% !important;
}
.wrapper {
  display: table;
  width: auto;
  position: relative;
  width: 50%;
}
.playpause {
  background-image: url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}

.case-panel {
  height: 100vh;
  padding: 60px 40px;
  overflow: scroll;
  background-color: #ffffffe0;
  box-sizing: border-box;
}

.map {
  width: ‘100px’;
  height: ‘100px’;
}

.g-map {
  max-width: 125%;
  cursor: pointer;
}
.contact_container {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 20px 35px;
  margin: 0px auto;
  // max-width: 450px;
  // margin-bottom: 180px;
  box-shadow: 1px 1px 41px 3px rgba(51, 51, 51, 0.38);
  * {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  .MuiInput-underline:before {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .form_children {
    padding: 10px 0px 20px;
    > div {
      padding-bottom: 5px;
    }
  }
  .button__container {
    margin: 0px !important;
  }
  ._title {
    font-size: 36px;
    line-height: 30px;
    margin: 0;
    font-family: roboto thin;
  }
  button {
    color: rgba(255, 255, 255, 0.8) !important;
  }
}

.gradient-text {
  transition: 0.4s;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 9px 13.9px rgba(140, 139, 139, 0.25);
}

.hover-gradient-text:hover {
  .title {
    transition: 0.4s;
    background-image: linear-gradient(
      43deg,
      #4158d0 0%,
      #c850c0 46%,
      #ffcc70 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 1px 9px 13.9px rgba(140, 139, 139, 0.25);
  }
}

// button {
//border: 2px solid transparent !important;
// -moz-border-image: -moz-linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%) !important;
// -webkit-border-image: -webkit-linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%) !important;
// border-image: linear-gradient(to bottom right,#4158D0 0%, #C850C0 46%, #FFCC70 100%) !important;
// border-image-slice: 1 !important;
//}

.font-30 {
  font-size: 30px;
}

.main-bottom {
  font-weight: bold;
  padding-right: 15px;
  font-size: 30px;
}

.dot {
  font-weight: bold;
  margin: auto 15px;
  font-size: 38px;
}

.compact.description {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
}

.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 0px !important;
}

.case-gallery {
  height: 100vh;
  padding: 30px;
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.case-gallery .BrainhubCarouselItem {
  width: 300px;
  margin: 30px !important;
  box-shadow: 1px 1px 31px 10px #25252587;
}

.case-study {
  .left-grid {
    margin-left: 0px;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .right-grid {
    margin-top: 0px;
    position: absolute;
    top: 0px;
    left: 50%;
    .heading {
      margin-top: 0px;
      font-size: 20px;
      margin-bottom: 0px;
      color: dimgray;
    }
    .title {
      margin-top: 0px;
      font-size: 30px;
      margin-bottom: 0px;
      font-weight: 400;
    }
    .p-title {
      padding-top: 18px;
      font-weight: bold;
    }
    .description {
      font-weight: 100;
      line-height: 20px;
    }
  }
}

.left-grid {
  .title {
    font-size: 52px;
    font-weight: bold;
  }
  .s-title {
    font-size: 25px;
  }
  .description {
    font-weight: 100;
    line-height: 20px;
    width: 90%;
  }
}

.right-grid {
  margin: 15px;
  .title {
    margin-top: 0px;
    font-weight: 100;
    font-size: 55px;
    margin-bottom: 0px;
  }
  .description {
    margin-top: 5px;
    font-weight: 100;
    margin-bottom: 0px;
  }
  .button-align {
    display: flex;
    flex-direction: row;
    .text {
      color: cadetblue;
    }
    .icon {
      margin-top: 3px;
      align-self: center;
      padding-left: 4px;
      font-size: 15px;
    }
  }
}

.tab-content {
  display: none;
  &.active {
    display: block;
  }
}

.text-center {
  text-align: center;
}


#form-dialog-title {
  padding: 10px 15px !important;
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.margin-t-b {
  margin-top: 5px;
  margin-bottom: 5px;
}

.left {
  .title {
    font-size: 52px;
    font-weight: bold;
  }
  .s-title {
    font-size: 45px;
  }
  .description {
    width: 90%;
    font-weight: 100;
    line-height: 20px;
  }
}

.right {
  .margin-l {
    margin-left: 10px;
    margin-top: 20px;
    .text {
      font-size: 40px;
      font-weight: 200;
      color: white;
      margin-left: 20px;
    }
    .carouse {
      margin-top: 20px;
    }
  }
}

.horizontalScroll {
  display: flex;
  flex-direction: row;
  .scrollImage {
    width: 100px;
    height: 100px;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.activity-card {
  padding-bottom: 20px;
  .card-content {
    padding-left: 20px;
    .card-image {
      width: 130px;
      height: 130px;
      border-color: "white";
      border-radius: 5px;
    }
    .card-details {
      padding-left: 50px;
      width: 500px;
    }
    .padding-l-t {
      padding-left: 160px;
      padding-top: 15px;
      .circular-green {
        background-color: "#33ba74";
        border-radius: 48px;
        width: 100px;
        height: 100px;
        .date {
          color: "white";
          text-align: "center";
          padding-top: 20px;
        }
      }
    }
  }
}

.thin {
  font-family: roboto thin;
}

.center-content {
  .thin-text {
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    line-height: 0px;
  }
  .div {
    align-content: center;
    .description {
      width: 500px;
      margin-top: 5px;
      font-weight: 100;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}

.red-carpet {
  margin: 50px 0px 30px 0px;
}

.margin {
  margin-left: 50px;
}

.title {
  font-size: 50px;
  font-weight: bold;
}
.flext-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -75px;
  .bold {
    font-weight: bold;
    font-size: 20px;
  }
  .font-size {
    font-size: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    .image-container {
      height: fit-content;
      margin-top: 20px;
      margin-right: 15px;
    }
  }
}

.bottom-footer {
  //background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  background: black;
}

.grey-bg {
  background: #e0e0e0;
  width: 500px;
  padding-bottom: 30px;
  .title {
    margin: 40px;
    font-size: 30px;
    padding-top: 20px;
  }
  .text {
    margin: 40px;
  }
}

.flex-footer {
  display: flex;
  flex-direction: row;
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 50%;
  padding-left: 90px;
  justify-content: space-evenly;
  border-top: 3px solid #000;
  .flex-column {
    display: flex;
    flex-direction: column;
    margin: 40px;
    .head {
      color: white;
      margin-top: 15px;
    }
    .link {
      color: white;
      text-decoration: none;
      margin-top: 12px;
      font-size: 13px;
      &:hover {
        color: white;
      }
    }
    .b-link {
      text-decoration: none;
      margin-top: 12px;
      color: white;
    }
    .img {
      width: 20px;
      height: 20px;
    }
  }
}
.bottom-footer {
  bottom: 0px;
  display: flex;
  flex-direction: row;
  background-color: #404040;
  justify-content: space-between;
  height: 30px;
  .footer-flex {
    margin-left: 120px;
    display: flex;
    flex-direction: row;
    .s-text {
      font-size: 13px;
      color: white;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-decoration: none;
      &:hover {
        color: white;
      }
    }
    .v {
      border-left-width: 0.5;
      border-left-color: white;
      height: 6px;
      padding-top: 5px;
    }
  }
  .a-text {
    font-size: 13px;
    color: white;
    margin-right: 90px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .grid {
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .nav-title {
    font-size: 25px;
    font-weight: bold;
    //margin-top: inherit;
  }
  .main-heading {
    color: black;
    font-weight: bold;
    font-size: 40px;
    padding-bottom: 10px;
    text-align: center;
  }
  .text {
    margin: 15px;
    padding-bottom: 10px;
    font-size: 23px;
  }
  .video-size {
    position: "absolute" !important;
    width: 400px !important;
    height: 400px;
  }
  .padding-top-grid {
    margin-top: 100px;
  }
  .padding-top-video {
    padding-top: 0px;
  }
  .grid-content {
    align-content: center;
  }
}

.f-f-uploader {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  input {
    width: 100%;
    height: 100%;
  }

  &.form {
    //width: 300px;
    //height: 300px;
    margin: 0 auto;
  }
}

.f-f-uploader.horizontal {
  height: 400px;
  background-color: #7a898596;
  border-radius: 6px;

  .helper-text {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: bold;
    opacity: 0.5;
  }
}

// .f-f-upload-btn {
// }

.f-f-upload-avatar {
  width: 100%;
  height: 100%;
  position: relative;
  .MuiAvatar-root {
    width: 100%;
    height: 100%;
    background-color: rgba(245, 241, 241, 0);
    svg {
      width: 70%;
      height: 70%;
      opacity: 0.6;
    }
  }
}

.f-f-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.f-f-upload-hidden {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.data-grid-container .data-grid .cell {
  padding: 5px !important;
  line-height: 28px;
  height: 100% !important;
}

.data-grid-container .data-grid .cell.bg-red {
  background-color: #fb79794a !important;
}

.data-grid-container .data-grid .cell.read-only {
  padding: 5px !important;
  line-height: 28px;
  color: white !important;
  background-color: #244518 !important;
}

.data-grid-container table {
  border-color: #244518 !important;
}

.data-grid-container .data-grid .cell.editing {
  padding: 0 !important;
}

.data-grid-container .data-grid .cell.editing > * {
  padding: 0px 5px;
  width: 100%;
  height: 100%;
  line-height: 18px;
  font-size: 18px;
  box-sizing: border-box;
  border-color: rgb(3, 67, 87) !important;
}

.data-grid-container .data-grid .cell .MuiInputBase-root {
  width: 100%;
  height: 100%;
}

.data-grid-container .data-grid .cell .MuiInputBase-root:before {
  border: none;
}

.data-grid-container .data-grid .cell .MuiInputBase-root:after {
  border: none;
}

.data-grid-container .data-grid .cell .data-editor {
  border-color: rgb(3, 67, 87) !important;
}

.data-grid-container .data-grid .cell .f-f-uploader {
  width: 80px;
  height: 80px;
}

.slick-dots {
  bottom: 8px !important;
  li {
    margin: 5px 0;
    button {
      width: 10px !important;
      &::before {
        color: white !important;
        width: 10px !important;
      }
    }
  }
}

.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  height: 100%;
}

.slick-prev {
  left: 0px !important;
}

.slick-next {
  right: 0px !important;
}
