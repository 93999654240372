body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ceo .page-header {
  padding: 20px 0px;
  /* margin-top: -60px; */
}

.ceo .main {
  padding-top: 0;
}

.ceo .ceo-words {
  margin: 20px 0px 30px 0px;
  max-width: 800px;
  font-size: 20px;
  font-style: italic;
}