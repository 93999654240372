@import "../../styles/colors";
@import "../../styles/variables";

.flex-middle {
  display: flex;
  align-items: center;
}

.flex-jus-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.flex-jus-center > div {
  width: 100%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-even {
  display: flex;
  justify-content: space-evenly;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.group-line {
  border-radius: 4px;
  margin-top: 5px;

  .textField__container {
    margin: 0;
    position: relative;
    top: -6px;
  }
}

.line-disabled {
  cursor: not-allowed;
  opacity: 0.6;
  &::after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.textField {
  &__container {
    margin: 10px 0px;
  }
}

.radio-group.row {
  flex-direction: row;
}

.textField__container,
.select__container,
.picker__container {
  &.box-view {
    &.short {
      width: 300px;
    }
    padding: 5px 10px;
    border: 1px solid $grey-100;
    > div,
    > div > div {
      border: none !important;
      &:before,
      &:after {
        content: unset;
      }
    }
  }
}

.AddressLane1.box-view {
  padding: 5px 15px;
  margin: 10px 0px;
  border: 1px solid $grey-100;
  box-sizing: border-box;
  height: 45px !important;
  > div {
    bottom: 9px !important;
  }
  hr {
    display: none;
  }

  &.round-edges {
    border-radius: 30px;
    box-shadow: 3px 6px 21px 0px #5b5a5a85;
    padding: 7px 22px;
    background-color: white !important;
  }
}

.picker__container.box-view {
  label {
    display: none;
  }
  > div {
    padding-left: 5px;
    margin-top: 0px;
  }
  &.round-edges {
    border-radius: 30px;
    box-shadow: 3px 6px 21px 0px #5b5a5a85;
    background-color: white;
  }
}

.allow-to-edges {
  // refactor
  padding: 0px !important;
  .allow-me {
    padding: 8px 24px;
  }
}
.button {
  &__container {
    padding: 10px 0px;
    .no-shadow {
      box-shadow: unset;
    }
    button {
      border-radius: 0px;
    }
  }
}

.current_location__container {
  margin: 15px 0px;
}

.radio__container {
  &.minimal {
    .radio__rootlabel {
      border: unset;
      align-items: flex-start;
      &.checked {
        background-color: $white;
        .radio__label {
          color: unset;
        }
        svg {
          color: $huex-red;
          opacity: 1;
        }
      }
    }
  }
  fieldset {
    width: 100%;
    margin: 0px;
  }
  .radio__rootlabel {
    margin: 10px 0px;
    border: 1px solid $grey-50;
    svg {
      opacity: 0.6;
    }
    .radio__label {
      font-size: 16px;
      color: $grey-60;
      font-family: fontBold;
    }
    &:not(.disabled):hover {
      transition: 0.2s all;
      border-color: $grey-100;

      svg {
        opacity: 0.8;
        color: $grey-110;
        // color: $white;
      }
      .radio__label {
        color: $grey-110;
      }
    }
    &.disabled {
      svg {
        display: none;
      }
    }
    &.checked {
      transition: 0.2s all;
      border-color: $huex-red;
      background-color: $huex-red;
      .radio__label {
        color: $white;
      }
      svg {
        color: $white;
        opacity: 1;
      }
    }
  }
}

.select__container {
  text-align: left;
  //margin: 15px 0px;
  label {
    font-size: 14px;
    color: $grey-60;
  }
  .select__box {
    width: 100%;
    .MuiInput-input-154 {
      font-family: fontBold;
    }
  }
  &.has-value {
    .select__box {
      &:before,
      &:after {
        border-color: $black;
      }
    }
  }
  &.minimal {
    &.large {
      .select__box > div {
        font-size: 32px;
        > div {
          padding-right: 40px;
        }
        &:after {
          font-size: 20px;
          float: unset;
        }
      }
    }
    .select__box > div {
      font-size: 18px;
      line-height: 32px;
      padding: 0px;
      display: flex;
      align-items: center;
      > div {
        padding: 0;
      }
      &:focus {
        background-color: unset !important;
      }
      &::after {
        font-size: 14px;
        // content: '\f078';
        font-family: "Font Awesome 5 Pro";
        padding: 0px 8px;
        color: $grey-55;
        font-weight: 800;
        transition: 0.2s;
        float: right;
      }
      &:hover {
        &::after {
          color: $grey-120;
          transition: 0.2s;
        }
      }
    }
  }
}

.minimal {
  [class*="MuiInput-underline-"] {
    &::before,
    &::after {
      border-color: $grey-100;
    }
  }
  svg {
    color: $grey-100;
    transition: 0.2s;
  }
  &:hover {
    button {
      color: $grey-55;
      transition: 0.2s;
    }
    svg {
      color: $grey-55;
      transition: 0.2s;
    }
  }
  button {
    color: $grey-55;
    transition: 0.2s;
  }
}

.location-icon {
  &::after {
    font-family: "Font Awesome 5 Pro";
    content: "\F14e";
    padding-right: 5px;
  }
}

.fileUploader {
  margin: 25px 0px;
  .uploadIcon {
    opacity: 0.5;
    margin-left: 90px;
  }
  p {
    font-size: 14px;
    color: $grey-100;
  }
  input {
    visibility: hidden;
  }
  .uploadPicturesWrapper {
    margin: 20px 0px;
    > div {
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
    .uploadPictureContainer {
      margin-right: 20px;
      position: relative;
      img {
        width: 200px;
        height: 140px;
        object-fit: cover;
      }
      .deleteImage {
        position: absolute;
        padding: 5px;
        right: -7px;
        top: -16px;
        cursor: pointer;
        &::after {
          content: "\F057";
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          position: absolute;
          left: 0;
          color: $huex-red;
          padding: 3px;
          background-color: white;
          border-radius: 25px;
        }
      }
    }
  }
  .upload__image__button {
    padding: 10px 24px;
    color: $black;
    font-size: 16px;
    border: 0px;
    border-radius: 30px;
    background-color: $huex-red-dark;
    color: $white;
    margin: 10px 0px 10px 40px;
    text-transform: uppercase;
    font-size: 0.9375rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &.profilePic {
      padding: 5px 10px;
      color: #000;
      font-size: 16px;
      border: 0px;
      background-color: #695c5c;
      color: #fff;
      margin: 0;
      text-transform: none;
      font-size: 1rem;
      cursor: pointer;
    }
  }
}

.image_container {
  > div {
    position: relative;
  }
  display: flex;
  flex-wrap: wrap;
  .cross_icon {
    &.show {
      display: none;
      i {
        font-size: 20px;
        position: absolute;
        left: 3px;
        display: block !important;
        top: 0px;
      }
    }
  }
}

.upload_images {
  margin-right: 5px;
  height: 160px;
  width: 200px;
}

.textarea__container {
  width: 100%;
  textarea {
    max-width: 100%;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid $grey-55;
    &:active {
      outline: none;
    }
  }
}

.input__multiplier__container {
  &.inline {
    .i_m_main {
      .select__container {
        display: inline-block;
        width: calc(50% - 10px);
        margin: 10px 0px;
        margin-right: 10px;
      }
    }
  }
  .i_delete_icon {
    padding: 5px;
    margin-top: 33px;
    margin-left: 10px;
    cursor: pointer;
    i {
      color: $grey-55;
      transition: 0.2s;
    }
    &:hover i {
      transition: 0.2s;
      color: $huex-red-dark;
    }
  }
  .i_m_main {
    .textField__container {
      margin-top: 30px;
    }
  }
  .button__container {
    padding: 0px;
    width: 100%;
    text-align: right;
  }
}

@media only screen and (max-width: 600px) {
  .textarea__container {
    textarea {
      background-color: none;
      font-family: font;
    }
  }

  .card__container {
    .card__inner {
    }
  }
}

.picker {
  margin: 20px 0px;
  > div {
    width: 100%;
  }
}

.button__container {
  button {
    border-radius: 20px;
  }

  &.danger {
    button {
      color: red;
    }
  }
  &.flat {
    button {
      background-color: unset;
    }
  }
  &.text-white {
    button {
      color: $white;
    }
  }
  &.bordered {
    button {
      border: 1px solid $grey-50;
      background-color: unset;
      color: $grey-110;
      transition: 0.2s;
      &:hover {
        border-color: $grey-55;
        color: $grey-120;
      }
    }
    &.active {
      button {
        border-color: $huex-red;
        color: $huex-red;
        transition: 0.2s;
      }
    }
  }
  .cross_icon {
    position: relative;
    left: 8px;
  }
  &.loading {
    button {
      position: relative;
      .button__content {
        opacity: 0;
        transition: 0.2s;
      }
      .button__loader {
        position: absolute;
        width: 35px;
        img {
          vertical-align: middle;
          width: 100%;
        }
      }
    }
  }
  &.right-icon {
    i {
      padding-left: 5px;
      transition: 0.2s;
    }
    &:hover {
      i {
        transform: translateX(5px);
        transition: 0.2s;
      }
    }
  }
  &.left-icon {
    i {
      padding-right: 10px;
      transition: 0.2s;
    }
  }
  &.rounded {
    button {
      border-radius: 30px;
    }
    &.sm {
      button {
        width: 30px;
        height: 30px;
        min-height: 30px;
        background-color: rgba(255, 82, 84, 0.84);
        box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.2);
        i {
          font-size: 10px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  &.bg-black {
    button {
      background-color: $black;
    }
  }
  &.bg-light {
    button {
      border: 1px solid $white;
      color: $white;
      background-color: $huex-red-light;
    }
  }
  &.bg-blue {
    button {
      background-color: $dark-brilliant-blue;
    }
  }
}

.search__box__field {
}

.form-container {
  .list__container {
    &.items__collec {
      display: flex;
      flex-wrap: wrap;
      .listItem__container {
        color: $white;
      }
    }
  }
}

.items__collec {
  .button__content {
    line-height: 20px;
  }
}

.counter__container {
  display: inline-block;
  margin-right: 50px;
  .counter__head {
  }
  .counter__main {
    display: flex;
    align-items: center;
    button {
    }
    > span {
      margin: 0 15px;
      font-size: 36px;
      margin-top: -3px;
    }
  }
}

.profile__interest__text {
  width: 550px;
  // min-width: 350px;
  margin: 10px 0 !important;
  &.edit {
    margin: 30px auto !important;
  }
  .p_i_input_field {
    align-items: baseline;
    > span {
      .button__container {
        padding: 0px;
      }
    }
    .textField__container {
      flex: 1;
      margin: 5px 0px;
    }
  }
}

.image__uploader__container {
  .image__uploader__input {
    input {
      position: absolute;
      display: none;
    }
    .button__content {
      label {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
  .image__uploader__preview {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px;
    > div {
      margin-right: 10px;
      position: relative;
      .cross_icon {
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -6px;
        background-color: $white;
        border-radius: 50%;
        opacity: 1;
        i {
          color: $grey-120;
          left: 0px;
          padding: 3px;
        }
      }
    }
  }
}

.predictions__container {
  position: relative;
  &.search-icon {
    .fa-search {
      position: absolute;
      z-index: 1;
      top: 24px;
      left: 20px;
      opacity: 0.8;
      display: block;
    }
    .AddressLane1 {
      padding-left: 50px !important;
    }
  }
  .fa-search {
    display: none;
  }
  .AddressLane1 {
    > div {
      font-size: 16px;
    }
    input {
      font-size: 18px !important;
      line-height: 30px !important;
      text-transform: capitalize;
    }
  }
}

.slider {
  &__container {
    .rc-slider {
      margin-left: 5px;
      .rc-slider-track {
        background-color: $huex-red-50;
      }
      .rc-slider-handle {
        border-color: $huex-red-dark;
        &:active {
          box-shadow: 0px 0px 5px $huex-red-50;
        }
      }
    }
    .property__cost {
      margin: 5px 7px;
    }
  }
}

.rc-slider-tooltip {
  z-index: 2;
  &-inner {
    font-size: 18px !important;
    padding: 6px 11px !important;
    height: auto !important;
    .property__cost {
      .cost_as_per_payscale {
        color: $white;
      }
    }
  }
}

.dialog_title {
  font-size: 18px;
}

.dialog_options {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: $desktop) {
  .profile__interest__text {
    width: 100%;
    .p_i_input_field {
      flex-direction: column;
      align-items: flex-end;
      .textField__container {
        width: 100%;
      }
      button {
        color: $black;
      }
    }
  }
}
