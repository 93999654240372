
html{
  scroll-behavior:smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTypography-h4{
    margin: 80px 0px !important; 
    text-align: center !important;
    font-family: 'fontMedium' !important;
}

.light-blue-box{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.form-container{
  min-width: 450px;
}

.textField__container{
  margin: 30px 0px;
}

.MuiButton-containedPrimary{
  color: white !important;
}

.MuiButton-contained{
  box-shadow: unset;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width:320px) and (max-width: 480px){
  .form-container{
    min-width: 100%;
  }
  .MuiTypography-h4{
    margin: 20px 0px !important; 
  }
  
}

@media all and (min-width:768px) and (max-width:1024px ){
  .MuiTypography-h4{
    margin: 0px !important; 
    padding: 30px 0px;
  }
}
